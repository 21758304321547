import { sleep } from '.';
import { ChainConstants } from 'constants/ChainConstants';
import { getTxResult } from './aelfUtils';
export interface AbiType {
  internalType?: string;
  name?: string;
  type?: string;
  components?: AbiType[];
}
export interface AbiItem {
  constant?: boolean;
  inputs?: AbiType[];
  name?: string;
  outputs?: AbiType[];
  payable?: boolean;
  stateMutability?: string;
  type?: string;
}

export interface ContractProps {
  contractABI?: AbiItem[];
  contractAddress: string;
  chainId?: number;
  aelfContract?: any;
}

interface ErrorMsg {
  error: {
    name?: string;
    code: number;
    message: string;
  };
}

export type ContractBasicErrorMsg = ErrorMsg;
type AElfCallViewMethod = (functionName: string, paramsOption?: any) => Promise<any | ErrorMsg>;

type AElfCallSendMethod = (functionName: string, paramsOption?: any) => Promise<ErrorMsg> | Promise<any>;

export class ContractBasic {
  public contract: any;
  public address: string;
  public methods?: any;
  constructor(options: ContractProps) {
    const { aelfContract, contractAddress } = options;
    this.address = contractAddress;
    this.contract = aelfContract;
  }

  public callViewMethod: AElfCallViewMethod = async (functionName, paramsOption) => {
    if (!this.contract) return { error: { code: 401, message: 'Contract init error1' } };
    try {
      // TODO upper first letter
      const functionNameUpper = functionName.replace(functionName[0], functionName[0].toLocaleUpperCase());
      const req = await this.contract[functionNameUpper].call(paramsOption);
      if (!req.error && (req.result || req.result === null)) return req.result;
      return req;
    } catch (e) {
      return { error: e };
    }
  };

  public callSendMethod: AElfCallSendMethod = async (functionName, paramsOption) => {
    if (!this.contract) return { error: { code: 401, message: 'Contract init error' } };
    if (!ChainConstants.aelfInstance.appName && !ChainConstants.aelfInstance.connected)
      return { error: { code: 402, message: 'connect aelf' } };
    try {
      const functionNameUpper = functionName.replace(functionName[0], functionName[0].toLocaleUpperCase());
      const req = await this.contract[functionNameUpper](paramsOption);
      if (req.error) {
        return {
          error: {
            code: req.error.message?.Code || req.error,
            message: req.errorMessage?.message || req.error.message?.Message,
          },
        };
      }
      const { TransactionId } = req.result || req;
      await sleep(1000);
      const validTxId = await getTxResult(TransactionId);
      return { TransactionId: validTxId };
    } catch (e: any) {
      if (e.message) return { error: e };
      return { error: { message: e.Error || e.Status } };
    }
  };

  public callSendPromiseMethod: AElfCallSendMethod = async (functionName, paramsOption) => {
    if (!this.contract) return { error: { code: 401, message: 'Contract init error' } };
    if (!ChainConstants.aelfInstance.appName && !ChainConstants.aelfInstance.connected)
      return { error: { code: 402, message: 'connect aelf' } };
    try {
      return this.contract[functionName](paramsOption);
    } catch (e) {
      return { error: e };
    }
  };
}
