import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useLanguage } from 'i18n';
import { ANTD_LOCAL } from 'i18n/config';
import { ConfigProvider } from 'antd';
import ModalProvider from './contexts/useModal';
import StoreProvider from 'contexts/useStore';
import AElfProvider from 'contexts/useAElf';
import ActiveInfoProvider from 'contexts/useActiveInfo';
import AElfContractProvider from 'contexts/useAElfContract';

import './index.css';
import { prefixCls } from 'constants/theme';

ConfigProvider.config({
  prefixCls,
});

function ContextProviders({ children }: { children?: React.ReactNode }) {
  const { language } = useLanguage();
  return (
    <ConfigProvider locale={ANTD_LOCAL[language]} autoInsertSpaceInButton={false} prefixCls={prefixCls}>
      <AElfContractProvider>
        <ModalProvider>
          <ActiveInfoProvider>{children}</ActiveInfoProvider>
        </ModalProvider>
      </AElfContractProvider>
    </ConfigProvider>
  );
}
ReactDOM.render(
  <AElfProvider>
    <StoreProvider>
      <ContextProviders>
        <App />
      </ContextProviders>
    </StoreProvider>
  </AElfProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
