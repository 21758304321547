import { request } from 'api';
import { cmsUrl } from 'constants/index';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import './Documentation.less';

interface LearningType {
  id: number;
  enTitle: string;
  zhTitle: string;
  icon: string;
  enDescription: string;
  zhDescription: string;
  link: string;
}

type LearningKeyType = keyof LearningType;

export default function Documentation() {
  const [learningList, setLearningList] = useState<LearningType[]>([]);
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const { language } = useLanguage();
  const getLearningList = useCallback(async () => {
    const res = await request.GET_LEARNING_LIST({
      params: {
        populate: 'material.icon',
      },
    });
    if (!res || !res?.data?.[0]?.attributes) return;
    const { material, enTitle, zhTitle } = res?.data?.[0]?.attributes;
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    const list = material.map((item: any) => {
      const { icon } = item;
      const url = icon?.data?.attributes?.url ?? '';
      return {
        ...item,
        icon: url,
      };
    });
    material && setLearningList(list);
  }, []);
  useEffect(() => {
    getLearningList();
  }, [getLearningList]);
  return (
    <div className="documentation-wrapper">
      <div className="content">
        <div className="documentation-container">
          <h3 className="title-wrapper">{title[language]}</h3>
          <div className="info-wrapper">
            {learningList &&
              learningList.map((item) => (
                <div key={item.id} className="learning-item">
                  {item.link ? (
                    <a href={item.link ?? ''}>
                      <img className="icon" src={`${cmsUrl}${item.icon}`} />
                      <h6>{item[`${language}Title` as LearningKeyType]}</h6>
                      <p>{item[`${language}Description` as LearningKeyType]}</p>
                    </a>
                  ) : (
                    <>
                      <img className="icon" src={`${cmsUrl}${item.icon}`} />
                      <h6>{item[`${language}Title` as LearningKeyType]}</h6>
                      <p>{item[`${language}Description` as LearningKeyType]}</p>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
