import { BasicActions } from 'contexts/utils';
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { basicActiveInfoView, ActiveInfoState } from './actions';

const INITIAL_STATE = { userProject: null };
const ActiveInfoContext = createContext<any>(INITIAL_STATE);

export function useActiveInfo(): [ActiveInfoState, BasicActions] {
  return useContext(ActiveInfoContext);
}

//reducer
function reducer(state: any, { type, payload }: any) {
  switch (type) {
    case basicActiveInfoView.destroy.type: {
      return {};
    }
    default: {
      const { destroy } = payload;
      if (destroy) return Object.assign({}, payload);
      return Object.assign({}, state, payload);
    }
  }
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <ActiveInfoContext.Provider value={useMemo(() => [{ ...state }, { dispatch }], [state, dispatch])}>
      {children}
    </ActiveInfoContext.Provider>
  );
}
