import { Logo, LegendLogo } from 'assets/images';
import clsx from 'clsx';
import NavMenu from 'component/NavMenu';
import { useLanguage } from 'i18n';
import { useLocation } from 'react-use';

export default function PcHeader() {
  const { language, changeLanguage } = useLanguage();
  const { pathname } = useLocation();

  return (
    <>
      <div className="flex-between-center header-left">
        {pathname === '/' ? (
          <a className="header-logo-wrapper" href="https://aelf.com/">
            <Logo className="logo" />
          </a>
        ) : (
          <a className="header-logo-wrapper" target="_self" href="/">
            <img className="legend-logo" src={LegendLogo} />
          </a>
        )}
        {pathname === '/' && <NavMenu />}
      </div>
      <div className="flex-between-center header-right">
        {/* <Button className="login-wrapper">Log in/Sign up</Button> */}
        <div className="language-wrapper">
          <span className={clsx(language === 'zh' && 'current-language')} onClick={() => changeLanguage('zh')}>
            繁体中文
          </span>
          <span className="delimiter">|</span>
          <span className={clsx(language === 'en' && 'current-language')} onClick={() => changeLanguage('en')}>
            English
          </span>
        </div>
      </div>
    </>
  );
}
