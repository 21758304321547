import { Drawer } from 'antd';
import { MLogo, MoreMenu, MClose, BLogo, LegendLogo } from 'assets/images';
import NavMenu from 'component/NavMenu';
import { useState } from 'react';
import { useLocation } from 'react-use';

export default function MHeader() {
  const [visible, setVisible] = useState<boolean>(false);
  const { pathname } = useLocation();
  return (
    <>
      <div className="flex-between-center mobile-content">
        {pathname === '/' ? (
          <a className="header-logo-wrapper" href="https://aelf.com/">
            <MLogo className="logo" />
          </a>
        ) : (
          <a className="header-logo-wrapper" target="_self" href="/">
            <img className="legend-logo" src={LegendLogo} />
          </a>
        )}

        <MoreMenu onClick={() => setVisible(true)} />
      </div>
      <Drawer
        title={<BLogo />}
        placement={'right'}
        closable={false}
        width={'15.5rem'}
        className="header-drawer-wrapper"
        extra={<MClose onClick={() => setVisible(false)} />}
        onClose={() => setVisible(false)}
        visible={visible}>
        <NavMenu />
        {/* <div className="language-wrapper">
          <span className={clsx(language === 'zh' && 'current-language')} onClick={() => changeLanguage('zh')}>
            繁体中文
          </span>
          <span className="delimiter">&nbsp;|&nbsp;</span>
          <span className={clsx(language === 'en' && 'current-language')} onClick={() => changeLanguage('en')}>
            English
          </span>
        </div> */}
      </Drawer>
    </>
  );
}
