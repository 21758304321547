import { useLanguage } from 'i18n';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Information.less';

export default function Information({ newsList }: { newsList: { [x: string]: string }[] }) {
  const { language } = useLanguage();
  const { t } = useTranslation();
  return (
    <div className="flex information-tabs-wrapper">
      <h3>{t('List board')}</h3>
      <ul className="information-list">
        {newsList &&
          newsList.map((news, index) => (
            <a key={index} href={news?.[`${language}Link`] ?? '/'}>
              <li className="flex-between-center">
                <div className="news-title">
                  <span>{news[`${language}Title`]}</span>
                </div>
                {news?.time && <span className="time">{moment(news?.time).format('LL')}</span>}
              </li>
            </a>
          ))}
      </ul>
    </div>
  );
}
