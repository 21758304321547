import { request } from 'api';
import { cmsUrl } from 'constants/index';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import './Partner.less';
interface PartnerType {
  id: number;
  logo: string;
  order: number;
}

export default function Partner() {
  const [partnerList, setPartnerList] = useState<PartnerType[]>([]);
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const { language } = useLanguage();
  const getPartnerList = useCallback(async () => {
    const res = await request.GET_PARTNERS_LIST({
      params: {
        populate: 'partner.logo',
      },
    });
    if (!res || !res?.data?.[0]?.attributes) return;
    const { partner, enTitle, zhTitle } = res?.data?.[0]?.attributes;
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    const list = partner.map((item: any) => {
      const { logo } = item;
      const url = logo?.data?.attributes?.url ?? '';
      return {
        ...item,
        logo: url,
      };
    });
    partner && setPartnerList(list.sort((a: PartnerType, b: PartnerType) => a.order - b.order));
  }, []);
  useEffect(() => {
    getPartnerList();
  }, [getPartnerList]);
  return (
    <div className="partner-wrapper">
      <div className="content">
        <h3 className="title-wrapper">{title[language]}</h3>
        <ul className="partner-list-wrapper">
          {partnerList.map((item) => (
            <li key={item.id}>
              <img src={`${cmsUrl}${item.logo}`} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
