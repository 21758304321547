import { API_REQ_FUNCTION, UrlObj } from './types';

export const BASE_APIS: UrlObj = {
  GET_NAV_LIST: '/api/navs',
  GET_BANNER_LIST: '/api/above-the-folds/',
  GET_BONUS_INFO: '/api/bonus-informations',
  GET_JUDGES_INFO: '/api/judges-infos/',
  GET_LEARNING_LIST: '/api/learning-materials',
  GET_PAST_REVIEW: '/api/past-reviews',
  GET_PARTNERS_LIST: '/api/partners',
  GET_QA_LIST: '/api/qas/',
  GET_EMAILS_INFO: '/api/emails',
  GET_USER_EMAIL: '/api/user-emails',
  POST_EMAIL: '/api/user-emails',
  GET_PROJECT_INFO: '/api/hackathons',
  GET_PROJECT_ALL_VOTE_INFO: '/vote/api/app/quadratic-vote/statistic-info-by-round',
  GET_PROJECT_VOTE_INFO: '/vote/api/app/quadratic-vote/project-info-by-round',
  GET_USER_PROJECT_VOTE_INFO: '/vote/api/app/quadratic-vote/user-info-by-round',
  GET_PROJECT_RULE: '/api/protocol-rules',
  GET_PROJECT_MATERIALS_INFO: '/api/materials',
  GET_PROJECT_INFORMATION: '/api/informations',
};

export const EXPAND_APIS: { [key: string]: UrlObj } = {};

export type BASE_REQ_TYPES = {
  [x in keyof typeof BASE_APIS]: API_REQ_FUNCTION;
};

export type EXPAND_REQ_TYPES = {
  [X in keyof typeof EXPAND_APIS]: {
    [K in keyof typeof EXPAND_APIS[X]]: API_REQ_FUNCTION;
  };
};
