import { useMobile } from 'contexts/useStore/hooks';
import { useWindowHeight } from 'hooks/useWindowSize';
import { forwardRef } from 'react';

const VideoWrap = forwardRef(() => {
  const isMobile = useMobile();
  const winHeight = useWindowHeight();
  return (
    <video
      autoPlay
      preload="auto"
      loop
      style={{
        width: '100%',
        height: `${winHeight}px`,
        objectFit: 'cover',
        display: 'block',
      }}
      poster={isMobile ? './assets/bannerTop.png' : './assets/poster.jpg'}
      muted={true}>
      <source src="./assets/banner.mp4" type="video/mp4"></source>
    </video>
  );
});

export default VideoWrap;
