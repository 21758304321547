import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function Rule({ rulesInfo }: { rulesInfo: string }) {
  return (
    <ReactMarkdown remarkPlugins={[gfm]} className="markdown-wrapper rule-markdown-wrapper">
      {rulesInfo}
    </ReactMarkdown>
  );
}
