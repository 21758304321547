import { useMobile } from 'contexts/useStore/hooks';
import { useEffect } from 'react';
import { useWindowHeight } from './useWindowSize';

export default function useAdaptScreen() {
  const winHeight = useWindowHeight();
  const mobile = useMobile();
  useEffect(() => {
    const MAIN = window.document.getElementsByClassName('hackathon-layout-content')[0];
    const MAIN_CHILDREN = Array.from(MAIN.childNodes);
    MAIN_CHILDREN.forEach((item) => {
      if (item.nodeName === 'DIV') {
        if (mobile) {
          (item as HTMLElement).style.height = `${winHeight}px`;
          (item as HTMLElement).style.position = 'relative';
          return;
        }
        (item as HTMLElement).style.height = `${winHeight}px`;
        (item as HTMLElement).style.position = 'relative';
      }
    });
    const contentList = window.document.getElementsByClassName('content');
    Array.from(contentList).forEach((item) => {
      if (item.nodeName === 'DIV') {
        if (mobile) {
          (item as HTMLElement).style.transform = 'none';
          return;
        }
        const parentElement = (item as HTMLElement).parentElement;
        const className = (parentElement as HTMLElement).getAttribute('class');
        if (className?.includes('save-email-wrapper') || className?.includes('partner-wrapper')) {
          (item as HTMLElement).style.transform = `scale(${winHeight / 1200}) translateY(-50%)`;
        } else {
          (item as HTMLElement).style.transform = `scale(${winHeight / 1200})`;
        }
      }
    });
  }, [winHeight, mobile]);
}
