import { ChainType } from 'types';
import { ChainConstantsType, supportedChainId } from '.';

type AElfOwnConstants = {
  CONTRACTS?: { [key: string]: string };
  LOGIN_INFO?: any;
  tokenContract?: string;
};

type Constants = ChainConstantsType & AElfOwnConstants;

export class ChainConstants {
  public id: number | string;
  static constants: Constants;
  static chainId: number | string;
  static apiChainId?: string;
  static chainType: ChainType;
  static aelfInstance?: any;
  constructor(id: number | string, type: ChainType, aelfInstance?: any) {
    this.id = id;
    ChainConstants['aelfInstance'] = aelfInstance;
    ChainConstants['chainType'] = type;
    this.setStaticAttrs();
  }
  getStaticAttr(attrName: keyof ChainConstantsType) {
    return ChainConstants.constants[attrName];
  }
  setStaticAttrs() {
    const attrs = supportedChainId['AELF'];
    ChainConstants['chainId'] = attrs.CHAIN_INFO.chainId;
    ChainConstants['constants'] = attrs;
  }
}
