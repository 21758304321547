import './SaveEmail.less';
import { request } from 'api';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';

interface MailType {
  enTitle: string;
  zhTitle: string;
  enDescription: string;
  zhDescription: string;
}
type MailKeyType = keyof MailType;

const REG = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;

export default function SaveEmail() {
  const [mailInfo, setMailInfo] = useState<MailType>();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');
  const getMailInfo = useCallback(async () => {
    const res = await request.GET_EMAILS_INFO();
    if (!res || !res?.data?.[0]?.attributes) return;
    const info = res?.data?.[0]?.attributes;
    info && setMailInfo(info);
  }, []);

  const postEmail = useCallback(
    async (postVal) => {
      const emailRes = await request.GET_USER_EMAIL();
      if (!emailRes || !emailRes?.data) return;
      const flag = emailRes?.data.some((item: any) => {
        const email = item?.attributes?.email;
        return email === postVal;
      });
      if (flag) {
        message.warning(t('EmailTip'));
        return;
      }
      try {
        const res = await request
          .POST_EMAIL({
            method: 'POST',
            data: {
              data: {
                email: postVal,
              },
            },
          })
          .catch((err) => {
            console.log(err?.response, '----');
            if (err?.response?.status !== 200) {
              const error = err?.response?.data?.error?.message;
              error && message.error(error ?? 'error');
            }
          });

        res?.meta && message.success('success');
      } catch (err) {
        console.error(err);
      }

      return;
    },
    [t],
  );

  useEffect(() => {
    getMailInfo();
  }, [getMailInfo]);
  const btnClick = useCallback(async () => {
    if (REG.test(val)) {
      setLoading(true);
      await postEmail(val);
      setLoading(false);
    } else {
      message.error('Email format is incorrect');
    }
  }, [postEmail, val]);
  return (
    <div className="save-email-wrapper">
      <div className="content">
        <h3 className="title-wrapper">{mailInfo?.[`${language}Title` as MailKeyType]}</h3>
        <div className="save-email-description">{mailInfo?.[`${language}Description` as MailKeyType]}</div>
        <div className="action-wrapper">
          <Input placeholder="Email: address" value={val} onChange={(e) => setVal(e.target.value.trim())} />
          <Button onClick={btnClick} loading={isLoading}>
            <span className="text">{t('Email registration')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
