import BigNumber from 'bignumber.js';
import { ZERO } from 'constants/misc';
import { useCallback, useMemo, useState } from 'react';
import { useTokenContract } from './useContract';
import useInterval from './useInterval';
import { useActiveWeb3React } from './web3';

export type NFTSymbols = {
  symbol: string;
  tokenId: number;
};
export const useAELFBalances = (
  symbols?: string | Array<string | undefined>,
  delay: null | number = 10000,
): [BigNumber[], () => void] => {
  const deArr = useMemo(() => (Array.isArray(symbols) ? symbols.map(() => ZERO) : []), [symbols]);
  const [balances, setBalances] = useState<BigNumber[]>(deArr);
  const [balance, setBalance] = useState<BigNumber>(ZERO);
  const { account } = useActiveWeb3React();
  const tokenContract = useTokenContract();

  const GetBalanceOnAELF = useCallback(
    async (tokenContract, symbols, account) =>
      await tokenContract?.callViewMethod('GetBalance', {
        symbol: symbols,
        owner: account,
      }),
    [],
  );

  const onGetBalance = useCallback(async () => {
    try {
      if (Array.isArray(symbols)) {
        if (!account) return setBalances(symbols.map(() => ZERO));
        const promiseAELF = symbols.map(async (i) => {
          if (i) {
            return await GetBalanceOnAELF(tokenContract, i, account);
          } else {
            return await Promise.resolve(ZERO);
          }
        });
        const tempBalances = await Promise.all(promiseAELF);
        const balance = tempBalances.map((item) => new BigNumber(item?.balance ?? 0));
        setBalances(balance);
        return;
      }

      if (!account || !symbols) {
        return setBalance(ZERO);
      }
      const AELF = await GetBalanceOnAELF(tokenContract, symbols, account);
      setBalance(new BigNumber(AELF?.balance ?? 0));
    } catch (error) {
      console.error(error, 'onGetBalance');
    }
  }, [GetBalanceOnAELF, account, symbols, tokenContract]);

  useInterval(
    () => {
      tokenContract && onGetBalance();
    },
    delay,
    [symbols, tokenContract],
  );
  return useMemo(() => {
    return [Array.isArray(symbols) ? balances : [balance], onGetBalance];
  }, [balance, balances, onGetBalance, symbols]);
};
