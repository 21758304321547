import useAdaptScreen from 'hooks/useAdaptScreen';
import useScrollScreen from 'hooks/useScrollScreen';
import { useEffectOnce } from 'react-use';
import BonusInformation from './component/BonusInformation';
import Documentation from './component/Documentation';
import JudgesInfo from './component/JudgesInfo';
import Partner from './component/Partner';
import PastReview from './component/PastReview';
import QA from './component/QA';
import SaveEmail from './component/SaveEmail';
import TopContent from './component/TopContent';
import './Overview.less';
export default function Overview() {
  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });
  useAdaptScreen();
  useScrollScreen();
  return (
    <>
      <TopContent />
      <BonusInformation />
      <JudgesInfo />
      <Documentation />
      <PastReview />
      <Partner />
      <QA />
      <SaveEmail />
    </>
  );
}
