import { useMemo } from 'react';
import { useAElf } from 'contexts/useAElf';

// useActiveWeb3React contains all attributes of useWeb3React and aelf combination
export function useActiveWeb3React() {
  const [{ address, aelfInstance }, { disConnect }] = useAElf();
  const tmpContext = useMemo(() => {
    return {
      chainId: 'AELF',
      account: address,
      library: undefined,
      apiChainId: 'null',
      error: null,
      active: !!address,
      deactivate: disConnect,
      connector: address ? 'NIGHT ELF' : undefined,
      aelfInstance: aelfInstance,
    };
  }, [address, aelfInstance, disConnect]);
  return tmpContext ?? {};
}
