import { Button } from 'antd';
import { request } from 'api';
import clsx from 'clsx';
import BannerBg from 'component/BannerBg';
import MyCountDown from 'component/MyCountDown';
import { useWindowHeight } from 'hooks/useWindowSize';
import { useLanguage } from 'i18n';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import './TopContent.less';

export default function TopContent() {
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [round, setRound] = useState<string>();
  const [text, setText] = useState<{ [x: string]: string }>({});
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const winHeight = useWindowHeight();
  const getDescribe = useCallback(async () => {
    const res = await request.GET_BANNER_LIST();
    if (!res || !res?.data?.[0]) return;
    const { startTime, endTime, enTitle, zhTitle, zhDescription, enDescription, round } = res.data[0]?.attributes ?? {};
    startTime && setStartTime(moment(startTime).unix() - moment().unix());
    endTime && setEndTime(moment(endTime).unix() - moment().unix());
    setRound(round);
    setText({
      zh: zhDescription,
      en: enDescription,
    });
    setTitle({
      zh: zhTitle,
      en: enTitle,
    });
  }, []);

  useEffect(() => {
    getDescribe();
  }, [getDescribe]);

  useEffect(() => {
    let ids: any;
    if (!startTime || !endTime) return;
    if (startTime > 0) {
      ids = setTimeout(() => {
        getDescribe();
      }, startTime * 1000 + 500);
    } else if (endTime > 0) {
      ids = setTimeout(() => {
        getDescribe();
      }, endTime * 1000 + 500);
    }
    return () => {
      ids && clearTimeout(ids);
    };
  }, [endTime, getDescribe, startTime]);

  return (
    <div className="top-content" id="top-content">
      <BannerBg />
      <div className="content">
        <div className="banner-content">
          <MyCountDown remainTime={startTime && startTime > 0 ? startTime : endTime ?? 0} />
          <div className="top-content-text-wrapper">
            <h3 className="title-wrapper">{title[language] ?? ''}</h3>
            <div className="active-description-wrapper">
              <div className="active-description">{text[language] ?? ''}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        // style={{ transform: `scale(${winHeight / 1200})`, transformOrigin: 'bottom' }}
        className="button-wrapper-content">
        <div className="button-wrapper">
          <Button
            disabled={!!((endTime && endTime <= 0) || (startTime && startTime > 0))}
            style={isMobile ? {} : { transform: `scale(${winHeight / 1200})` }}
            onClick={() => {
              // ('https://github.com/AElfProject/AElf');
            }}
            className={clsx(
              !startTime || startTime > 0 ? 'coming-soon' : endTime && endTime > 0 ? 'register' : 'ended',
            )}>
            <a href="https://aelf.devpost.com/">
              {t(!startTime || startTime > 0 ? 'Coming soon' : endTime && endTime > 0 ? 'Register' : 'Ended')}
            </a>
          </Button>
          <Button
            // disabled
            style={isMobile ? {} : { transform: `scale(${winHeight / 1200})` }}
            onClick={() => {
              round && navigate(`/hackathon/${round}`);
            }}>
            Grant
          </Button>
        </div>
      </div>
    </div>
  );
}
