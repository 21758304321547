import FlipClock from './FlipClock';
import { useCallback, useEffect, useState } from 'react';
let ids: any;
export default function FlipCountdown({ remainTime = 0 }: { remainTime?: number }) {
  const [time, setTime] = useState(remainTime);

  const sleep = useCallback((time: number) => {
    return new Promise<void>((resolve) => {
      ids = setTimeout(() => {
        resolve();
        clearTimeout(ids);
      }, time);
    });
  }, []);

  const onCountDown = useCallback(async () => {
    let t = remainTime;
    while (t > 0) {
      setTime(--t);
      await sleep(1000);
    }
  }, [remainTime, sleep]);

  useEffect(() => {
    onCountDown();
    return () => {
      ids && clearTimeout(ids);
    };
  }, [onCountDown, remainTime]);
  return <FlipClock remainTime={time} />;
}
