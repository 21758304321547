import './Material.less';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export default function Material({ materialsInfo }: { materialsInfo: string }) {
  return (
    <ReactMarkdown remarkPlugins={[gfm]} className="markdown-wrapper material-markdown-wrapper">
      {materialsInfo}
    </ReactMarkdown>
  );
}
