import * as AELF_MAIN from './platform/aelf-main';
import { SupportedChainId, SupportedELFChainId } from './chain';

export type ChainConstantsType = typeof AELF_MAIN;

export interface WalletInfo {
  connector?: string;
  name: string;
  icon: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}
export type CHAIN_ID_TYPE = keyof typeof supportedChainId;

export const DEFAULT_CHAIN = SupportedChainId.MAINNET;

export const supportedChainId = {
  [SupportedELFChainId.MAINNET]: AELF_MAIN,
};
export const CHAIN_NAME: { [chainId in SupportedELFChainId]: string } = {
  [SupportedELFChainId.MAINNET]: 'AELF Mainnet',
};
export const ACTIVE_CHAIN: any = {
  [SupportedChainId.MAINNET]: 'Ethereum',
  [SupportedChainId.KOVAN]: 'Kovan',
  [SupportedChainId.BSC_MAINNET]: 'BSC Mainnet',
  [SupportedChainId.BSC_TESTNET]: 'BSC Testnet',
  [SupportedChainId.HECO_MAINNET]: 'HECO Mainnet',
  [SupportedChainId.HECO_TESTNET]: 'HECO Testnet',
  [SupportedChainId.OEC_MAINNET]: 'OEC Mainnet',
  [SupportedChainId.OEC_TESTNET]: 'OEC Testnet',
  [SupportedChainId.POLYGON_MAINNET]: 'Polygon Mainnet',
  [SupportedChainId.POLYGON_TESTNET]: 'Polygon Testnet',
  [SupportedELFChainId.MAINNET]: 'AELF',
};
export const PROD_CHAIN: { [key: number]: string } = {
  [SupportedChainId.MAINNET]: 'Ethereum',
  [SupportedChainId.BSC_MAINNET]: 'BSC Mainnet',
};

export const NetworkContextName = 'NETWORK';

const prodNetworkList = [
  {
    title: CHAIN_NAME[SupportedELFChainId.MAINNET],
    info: AELF_MAIN.CHAIN_INFO,
  },
];

const testNetworkList = [
  {
    title: CHAIN_NAME[SupportedELFChainId.MAINNET],
    info: AELF_MAIN.CHAIN_INFO,
  },
];

export const networkList = process.env.REACT_APP_ENV === 'prod' ? prodNetworkList : testNetworkList;
export const cmsUrl = process.env.REACT_APP_HACKATHON_CMS_URL;
