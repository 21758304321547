export const tokenContract = '7RzVGiuVWkvL4VfVHdZfQF2Tri3sgLe9U991bohHFfSRZXuGX';
export const voteContract = 'buePNjhmHckfZn9D8GTL1wq6JgA8K24SeTWnjCNcrz6Sf1FDh';
export const LOGIN_INFO = {
  chainId: 'tDVV',
  payload: {
    method: 'LOGIN',
    contracts: [
      {
        chainId: 'tDVV',
        contractAddress: tokenContract,
        contractName: 'Token contract',
        description: 'You can transfer, approve, get balance, etc.',
        github: 'https://github.com/AElfProject/AElf/blob/dev/protobuf/token_contract.proto',
      },
    ],
  },
};

export const CHAIN_INFO = {
  chainId: 'tDVV',
  exploreUrl: 'https://tdvv-explorer.aelf.io/',
  // rpcUrl: 'http://192.168.67.166:8000',
  rpcUrl: 'https://tdvv-explorer.aelf.io/chain',
};

export const CHAIN_ID = 'tDVV';
