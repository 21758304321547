import clsx from 'clsx';
import { cmsUrl } from 'constants/index';
import { useLanguage } from 'i18n';
import { JudgeInfoKey, JudgeInfoType } from 'types';

export default function JudgesMobile({ judgesList, className }: { judgesList: JudgeInfoType[]; className?: string }) {
  const { language } = useLanguage();
  return (
    <ul className={clsx(className)}>
      {judgesList.map((judge, index) => (
        <li className={clsx('flex', index === judgesList.length - 1 && 'grid-span-self')} key={judge.id}>
          <div className="judge-cover">
            <img className="judge-cover-img" src={`${cmsUrl}${judge.cover}`} />
          </div>
          <div>
            <p className="judge-name"> {judge[`${language}JudgeName` as JudgeInfoKey]}</p>
            <p className="introduction">{judge[`${language}Introduction` as JudgeInfoKey]}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}
