import img0 from './0.svg';
import img1 from './1.svg';
import img2 from './2.svg';
import img3 from './3.svg';
import img4 from './4.svg';
import img5 from './5.svg';
import img6 from './6.svg';
import img7 from './7.svg';
import img8 from './8.svg';
import img9 from './9.svg';
export default { imgList: [img0, img1, img2, img3, img4, img5, img6, img7, img8, img9] };
