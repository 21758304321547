import { ConfigProvider, Layout } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ANTD_LOCAL } from './i18n/config';
import './App.less';
import { routes } from 'routes';
import { prefixCls } from 'constants/theme';
// import { Suspense } from 'react';
import clsx from 'clsx';
import { useLanguage } from 'i18n';
import Header from 'component/Header';
import Footer from 'component/Footer';
import Modals from 'Modals';
const { Content } = Layout;

function App() {
  const { language } = useLanguage();
  return (
    <>
      <Modals />
      <ConfigProvider locale={ANTD_LOCAL[language]} prefixCls={prefixCls}>
        <Layout className={clsx(prefixCls && `${prefixCls}-layout`)}>
          <Content>
            <BrowserRouter>
              <Header />

              {/* TODO */}
              {/* <Suspense fallback={<Spin />}> */}
              <Routes>
                {routes.map((route) => {
                  return <Route key={route.path} path={route.path} element={<route.element />} />;
                })}
              </Routes>
              {/* </Suspense> */}
            </BrowserRouter>
          </Content>
          <Footer />
        </Layout>
      </ConfigProvider>
    </>
  );
}

export default App;
