import './NavMenu.less';
import { useCallback, useEffect, useState } from 'react';
import { request } from 'api';
import { Menu } from 'antd';
import { useLanguage } from 'i18n';
import { useMobile } from 'contexts/useStore/hooks';

interface NavChildItemType {
  enTitle: string;
  zhTitle: string;
  link?: string;
  id: string;
}
interface NavItemType {
  id: number;
  enTitle: string;
  zhTitle: string;
  item: NavChildItemType[];
}

type Type = keyof NavItemType & keyof NavChildItemType;

const { SubMenu, Item } = Menu;

export default function NavMenu() {
  const [navList, setNavList] = useState<NavItemType[]>([]);
  const [openKeys, setOpenKeys] = useState<string>('');

  const isMobile = useMobile();
  const { language } = useLanguage();
  const getNavList = useCallback(async () => {
    const res = await request.GET_NAV_LIST({
      params: {
        populate: 'navList.item',
      },
    });
    if (!res || !res?.data?.[0]) return;
    const navList = res?.data[0]?.attributes?.navList;
    navList && setNavList(navList);
  }, []);

  useEffect(() => {
    getNavList();
  }, [getNavList]);

  return (
    <Menu
      openKeys={isMobile ? [openKeys] : undefined}
      className={isMobile ? 'nav-wrapper-mobile' : 'nav-wrapper'}
      getPopupContainer={(v) => v}
      mode={isMobile ? 'inline' : 'horizontal'}>
      {navList.map((nav) => {
        if (!nav?.item?.length) {
          return (
            <Item key={`menu${nav.id}`} title={nav[`${language}Title` as Type]}>
              {nav[`${language}Title` as Type]}
              <a href="/" target="_self"></a>
            </Item>
          );
        }

        return (
          <SubMenu
            key={`menu${nav.id}`}
            title={nav[`${language}Title` as Type]}
            onTitleClick={(e) => {
              if (e.key === openKeys) return setOpenKeys('');
              setOpenKeys(e.key);
            }}>
            {nav?.item &&
              nav?.item.map((item) => (
                <Item key={`subItem${item.id}`}>
                  <span>{item[`${language}Title` as Type]}</span>
                  {item?.link && <a href={item?.link}></a>}
                </Item>
              ))}
          </SubMenu>
        );
      })}
    </Menu>
  );
}
