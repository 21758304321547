import { Button } from 'antd';
import clsx from 'clsx';
import VoteCard from 'component/VoteCard';
import { useAElf } from 'contexts/useAElf';
import { useModal } from 'contexts/useModal';
import { basicModalView } from 'contexts/useModal/actions';
import { useMobile } from 'contexts/useStore/hooks';
import { useAELFBalances } from 'hooks/useBalances';
import { useTranslation } from 'react-i18next';
import { shortenAddress } from 'utils';
import { divDecimals } from 'utils/calculate';
import CommonModal from '../../components/CommonModal';
import './styles.less';

export default function WalletModal() {
  const [{ walletModal }, { dispatch }] = useModal();
  const isMobile = useMobile();
  const [{ address }, { connect, disConnect }] = useAElf();
  const [[balance]] = useAELFBalances('ELF');
  const { t } = useTranslation();
  return (
    <CommonModal
      width="auto"
      visible={walletModal}
      closable={false}
      title={
        <>
          <span>{t('Vote for this project')}</span>
          <span className="close-x" onClick={() => dispatch(basicModalView.setWalletModal.actions(false))}></span>
        </>
      }
      onCancel={() => dispatch(basicModalView.setWalletModal.actions(false))}
      className={clsx('common-modals', 'wallet-modal')}>
      <div className="flex wallet-tip">
        <div className="tip-icon"></div>
        <div className="tio-content">
          <p>
            {t('WalletTip')}
            <br />
            {t('WalletTip1')}
            <a
              href={
                'https://medium.com/aelfblockchain/hackathon-grant-function-fully-activated-vote-to-win-100x-rewards-and-nfts-1f34e442ce4e'
              }>
              {t('WalletTipLink')}
            </a>
            <br />
            {t('WalletTip2')}
          </p>
        </div>
      </div>
      <div className="connect-wallet-wrapper">
        <h4 className="flex-between-center">
          {t('Connect Wallet')}
          {address && !isMobile && (
            <span className="log-out" onClick={() => disConnect()}>
              {t('Log out')}
            </span>
          )}
        </h4>
        {!address && (
          <Button
            className="wallet-elf"
            onClick={() => {
              connect();
            }}>
            ELF
          </Button>
        )}
        {address && (
          <div className="flex-between-center network-wrapper">
            <div>
              <p>
                {t('Account')}&nbsp;<span className="value">{shortenAddress(address)}</span>
                {isMobile && <br />}
                {t(`Network`)}&nbsp;<span className="value">{'Side tDVV'}</span>
              </p>
              <p>
                {t(`Balance`)}&nbsp;<span className="value">{divDecimals(balance, 8).toFormat(2)} ELF</span>
              </p>
            </div>
            <div className="chain-icon"></div>
          </div>
        )}
        {address && <VoteCard />}
      </div>
    </CommonModal>
  );
}
