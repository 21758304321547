import { request } from 'api';
import BigNumber from 'bignumber.js';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './BonusInformation.less';
interface AwardList {
  id: number;
  zhPrizeTitle: string;
  enPrizeTitle: string;
  money: number;
}
type AwardItemKey = keyof AwardList;
export default function BonusInformation() {
  const [title, setTitle] = useState<{ [x: string]: string }>();
  const { t } = useTranslation();
  const [awardList, setAwardList] = useState<AwardList[]>();
  const { language } = useLanguage();
  const isMobile = useMobile();
  const getBonus = useCallback(async () => {
    const bonusInfo = await request.GET_BONUS_INFO({
      params: {
        populate: 'AwardsInfo',
      },
    });
    if (!bonusInfo || !bonusInfo?.data?.[0]?.attributes) return;
    const { AwardsInfo, enTitle, zhTitle } = bonusInfo?.data?.[0]?.attributes;
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    AwardsInfo && setAwardList(AwardsInfo);
  }, []);
  useEffect(() => {
    getBonus();
  }, [getBonus]);
  return (
    <div id="bonus" className="bonus-information-wrapper">
      <div className="content">
        <div className="bonus-t"></div>
        <div className="bonus-out-content">
          {title && isMobile && <h3 className="title">{title[language] ?? ''}</h3>}
          <div className="bonus-content">
            {title && !isMobile && <h3>{title[language] ?? ''}</h3>}
            <ul className="bonus-list">
              {awardList?.map((item) => (
                <li key={item.id}>
                  <div className="price-wrapper">
                    <p className="price">${new BigNumber(item.money).toFormat()}</p>
                  </div>
                  <p className="price-title">{item[`${language}PrizeTitle` as AwardItemKey]}</p>
                </li>
              ))}
            </ul>
            <p className="award-tip">* {t('AwardTip')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
