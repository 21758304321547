import { useCallback, useMemo, useState } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { PastReviewType } from 'types';
import { config } from 'react-spring';
import PastCard from './PastCard';
import BaseCarousel3D from 'components/BaseCarousel3D';
let startX: any;
let endX: any;
const MIN_TOUCH_DISTANCE = 50;

export default function PastReview3DPC({ pastReview }: { pastReview?: PastReviewType[] }) {
  const [goToSlide, setGoToSlide] = useState<number>(0);
  const slides = useMemo(() => {
    return pastReview?.map((past, index) => {
      return {
        key: past.id,
        onClick: () => {
          setGoToSlide(index);
        },
        content: <PastCard item={past} disabledNav={goToSlide !== index} />,
      };
    });
  }, [goToSlide, pastReview]);

  const handleTouchStart = useCallback((e) => {
    startX = e.touches[0].clientX;
  }, []);
  const handleTouchMove = useCallback((e) => {
    endX = e.touches[0].clientX;
  }, []);
  const handleTouchEnd = useCallback(() => {
    if (!pastReview?.length) return;
    const distance = Math.abs(startX - endX);
    if (distance > MIN_TOUCH_DISTANCE) {
      if (startX > endX) {
        // carousel.next();
        setGoToSlide((v) => {
          const next = v + 1;
          if (next >= pastReview.length) return 0;
          return next;
        });
      } else {
        // carousel.prev();
        setGoToSlide((v) => {
          const prev = v - 1;
          if (prev < 0) return pastReview.length - 1;
          return prev;
        });
      }
    }
  }, [pastReview?.length]);

  return (
    <div
      className={'pc-carousel-3d-wrapper'}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <BaseCarousel3D
        showNavigation={false}
        slides={slides ?? []}
        goToSlide={goToSlide}
        offsetRadius={1}
        animationConfig={config.gentle}
        opacity={1}
        baseScale={2}
      />
    </div>
  );
}
