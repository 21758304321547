import { useMemo } from 'react';
import { getBeforeNum, getTimeCountDown } from './utils';
import './styles.less';

import image from './img';
import { useTranslation } from 'react-i18next';
const timeNum1 = [0, 1, 2, 3, 4, 5];
const timeNum2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const { imgList } = image;
function FlipClockDivider() {
  return (
    <div className="flip-clock-divider">
      <span className="flip-clock-dot top"></span>
      <span className="flip-clock-dot bottom"></span>
    </div>
  );
}
function FlipClockItem({ item }: { item: any }) {
  return (
    <div className="flip-clock-item">
      <div className="up">
        <div className="shadow"></div>
        <div className="inn">
          <img src={imgList[item]} />
        </div>
      </div>
      <div className="down">
        <div className="shadow"></div>
        <div className="inn">
          <img src={imgList[item]} />
        </div>
      </div>
    </div>
  );
}

export default function FlipClock({ remainTime }: { remainTime: number }) {
  const textList = useMemo(() => ['Days', 'Hours', 'Minutes', 'Seconds'], []);
  const { t } = useTranslation();
  const timeArr = useMemo(() => {
    const date = getTimeCountDown(remainTime);
    return [date.d[0], date.d[1], date.h[0], date.h[1], date.m[0], date.m[1], date.s[0], date.s[1]];
  }, [remainTime]);
  return (
    <div className="flip-clock-container">
      <div className="flip-clock-wrapper">
        {timeArr.map((item, index) => {
          const type = index % 2;
          const numArr = type === 0 ? timeNum1 : timeNum2;
          return (
            <div key={index}>
              <ul key={index} className="flip play">
                {numArr.map((item0) => {
                  let className = 'flip-clock-hide';
                  if (item0 === parseInt(item)) className = 'flip-clock-active';
                  if (item0 === getBeforeNum(Number(item), type)) className = 'flip-clock-before';
                  // if (item0 === getAfterNum(Number(item))) className = 'flip-clock-after';
                  return (
                    <li key={item0} className={className}>
                      <FlipClockItem item={item0} />
                    </li>
                  );
                })}
              </ul>
              {(index === 1 || index === 3 || index === 5) && <FlipClockDivider />}
            </div>
          );
        })}
      </div>
      <div className="text-wrapper">
        {textList.map((item, index) => (
          <div key={index}>{t(item)}</div>
        ))}
      </div>
    </div>
  );
}
