import { Button } from 'antd';
import clsx from 'clsx';
import { cmsUrl } from 'constants/index';
import { basicModalView } from 'contexts/useModal/actions';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import moment from 'moment';
import { ProjectItemType, ProjectKeyType, ProjectType, REFRESH_USER_VOTE } from 'pages/ActiveInfo/hooks/Updater';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { eventBus, formatVal, unitConverter } from 'utils';
import gfm from 'remark-gfm';

const formatChildren = ({ children }: { children: any }) => {
  return (
    <>
      {children}
      <br />
    </>
  );
};

export default function ProjectCard({
  project,
  projectVote,
  disabled = true,
}: {
  project: ProjectType;
  projectVote?: { [x: string]: ProjectItemType };
  disabled?: boolean;
}) {
  const [isFold, setFold] = useState<boolean>(true);
  const { language } = useLanguage();
  const isMobile = useMobile();
  const modalDispatch = useModalDispatch();
  const { t } = useTranslation();
  const linkWrap = useCallback(
    (project: ProjectType) => (
      <div className={clsx('link-wrapper', !isFold && 'show-link-wrapper')}>
        {project?.github && (
          <a className="github-link" href={project?.github}>
            Github
          </a>
        )}
        {project?.demoLink && (
          <a className="demo-link" href={project?.demoLink}>
            Demo
          </a>
        )}
        {project?.demoVideoLink && (
          <a className="demo-video-link" href={project?.demoVideoLink}>
            Demo Video
          </a>
        )}
      </div>
    ),
    [isFold],
  );
  return (
    <li className="project-item-wrapper" key={project.projectId}>
      <div className="flex text-introduce-wrapper">
        <div className="cover-wrapper">
          <img src={`${cmsUrl}${project?.[isMobile ? 'mCover' : 'pcCover']?.data?.attributes?.url}`} />
          {!isMobile && linkWrap(project)}
        </div>
        <div className="description-wrapper">
          <h5>{project?.[`${language}Title` as ProjectKeyType] ?? ''}</h5>
          <ReactMarkdown
            components={
              isFold
                ? {
                    p: formatChildren,
                    h1: formatChildren,
                    h2: formatChildren,
                    h3: formatChildren,
                    h4: formatChildren,
                    h5: formatChildren,
                    h6: formatChildren,
                    li: formatChildren,
                    ol: formatChildren,
                    ul: formatChildren,
                    strong: formatChildren,
                  }
                : undefined
            }
            remarkPlugins={[gfm]}
            className={clsx('markdown-wrapper description', isFold && 'ellipsis3')}>
            {(project?.[`${language}Description` as ProjectKeyType] ?? '') as string}
          </ReactMarkdown>
          {/* <p className={clsx('description', isFold && 'ellipsis3')}>
            {project?.[`${language}Description` as ProjectKeyType] ?? ''}
          </p> */}
          {isMobile && linkWrap(project)}
          <div className="flex-between-center">
            <span className="time">{moment(project?.date).format('LL')}</span>
            <span className={clsx('collapse-btn', !isFold && 'no-fold')} onClick={() => setFold(!isFold)}>
              {isFold ? t('more') : t('close')}
            </span>
          </div>
        </div>
      </div>
      <div className="flex-between-center project-action">
        <div className="flex action-left">
          <div>
            <span>{t('Community Contribution')}</span>
            <span>{`${unitConverter(projectVote?.[project.projectId]?.voteValue)} ELF`}</span>
          </div>
          <div>
            <span>{t('Votes Received')}</span>
            <span>{formatVal(projectVote?.[project.projectId]?.votes, 0)}</span>
          </div>
          <div>
            <span>{t('Grant Pool')}</span>
            <span>{`${unitConverter(projectVote?.[project.projectId]?.supportValue)} ELF`}</span>
          </div>
        </div>
        <div className="action-right">
          <Button
            disabled={disabled}
            onClick={() => {
              eventBus.emit(REFRESH_USER_VOTE);
              modalDispatch(basicModalView.setWalletModal.actions(true, { projectId: project.projectId }));
            }}>
            {t('Vote')}
          </Button>
        </div>
      </div>
    </li>
  );
}
