import { Button, message } from 'antd';
import Input from 'antd/lib/input/Input';
import BigNumber from 'bignumber.js';
import { ChainConstants } from 'constants/ChainConstants';
import { useActiveInfo } from 'contexts/useActiveInfo';
import { useAElfContract } from 'contexts/useAElfContract/hooks';
import { useModal } from 'contexts/useModal';
import { basicModalView } from 'contexts/useModal/actions';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useLockCallback } from 'hooks';
import { useActiveWeb3React } from 'hooks/web3';
import { REFRESH_USER_VOTE } from 'pages/ActiveInfo/hooks/Updater';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eventBus } from 'utils';
import { checkElfAllowanceAndApprove, messageHTML } from 'utils/aelfUtils';
import './VoteCard.less';
export default function VoteCard() {
  const [{ poolData }] = useModal();
  const voteContract = useAElfContract(ChainConstants.constants.voteContract);
  const tokenContract = useAElfContract(ChainConstants.constants.tokenContract);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { account } = useActiveWeb3React();
  const [voteCount, setVoteCount] = useState<string>('');
  const [{ userProject }] = useActiveInfo();
  const modalDispatch = useModalDispatch();
  const { t } = useTranslation();
  const userVote = useMemo(() => {
    return userProject?.[poolData?.projectId];
  }, [poolData?.projectId, userProject]);

  const voteHandle = useCallback(async () => {
    if (!poolData?.projectId || !voteCount || !account || !voteContract || !tokenContract) return;
    setLoading(true);
    const approve = await checkElfAllowanceAndApprove(
      tokenContract,
      'ELF',
      account,
      ChainConstants.constants.voteContract,
      1,
    );
    if (!approve) return setLoading(false);
    const projectId = poolData?.projectId?.replace(/\b(0+)/gi, '');
    const VoteRes = await voteContract?.callSendMethod('Vote', {
      projectId,
      votes: voteCount,
    });
    setLoading(false);
    if (VoteRes?.error) {
      message.error((VoteRes?.error?.message || 'vote error').replace('AElf.Sdk.CSharp.AssertionException:', ''));
      return;
    }
    VoteRes?.TransactionId && messageHTML(VoteRes?.TransactionId, 'success');
    setVoteCount('');
    modalDispatch(basicModalView.setWalletModal.actions(false));
    const ids = setTimeout(() => {
      eventBus.emit(REFRESH_USER_VOTE);
      clearTimeout(ids);
    }, 3000);
  }, [account, poolData?.projectId, modalDispatch, tokenContract, voteContract, voteCount]);

  const btnClick = useLockCallback(voteHandle, [voteHandle]);

  const inputChange = useCallback((e) => {
    if (e.target.value < 1 || isNaN(Number(e.target.value))) {
      setVoteCount('');
      setDisabled(true);
      return;
    }
    if (e.target.value) {
      setVoteCount(new BigNumber(e.target.value).toFixed(0));
      setDisabled(false);
      return;
    }
    setDisabled(true);
    setVoteCount('');
  }, []);
  return (
    <div className="vote-action-card">
      <div className="flex vote-input-wrapper">
        <div className="vote-input">
          <p>{t('Vote for this project')}</p>
          <Input value={voteCount} onChange={inputChange} />
        </div>
        <div className="vote-given">
          <p>{t('Votes history')}</p>
          <span>{userVote?.vote ?? '0'}</span>
        </div>
      </div>
      <div className="payment-votes">
        <p>{t('Payments history')}</p>
        <p>{userVote?.cost ?? 0}</p>
        <p>ELF</p>
      </div>
      <Button loading={isLoading} disabled={disabled} className="confirm-btn" onClick={btnClick}>
        {t('Confirm')}
      </Button>
    </div>
  );
}
