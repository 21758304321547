function unityNumber(n: number) {
  return n.toString().padStart(2, '0');
}
export function getTimeCountDown(remainTime: number) {
  const d = Math.floor(remainTime / 86400);
  const h = Math.floor((remainTime - d * 86400) / 3600);
  const m = Math.floor((remainTime - d * 86400 - h * 3600) / 60);
  const s = Math.floor(remainTime % 60);
  return {
    d: unityNumber(d),
    h: unityNumber(h),
    m: unityNumber(m),
    s: unityNumber(s),
  };
}

export function getBeforeNum(activeNum: number, type: number) {
  if (activeNum === 5 && type === 0) {
    return 0;
  } else if (activeNum === 9 && type === 1) {
    return 0;
  } else {
    return activeNum + 1;
  }
}
export function getAfterNum(activeNum: number) {
  if (activeNum === 0) return undefined;
  return activeNum - 1;
}
