import { Radio, Space } from 'antd';
import { useMobile } from 'contexts/useStore/hooks';
import moment from 'moment';
import { ProjectItemType, ProjectType } from 'pages/ActiveInfo/hooks/Updater';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Project.less';
import ProjectCard from './ProjectCard';

export default function Project({
  projectList,
  projectVote,
  disabled,
}: {
  projectList?: ProjectType[];
  projectVote?: { [x: string]: ProjectItemType };
  disabled?: boolean;
}) {
  const [filterCheck, setFilterCheck] = useState<string>('Descending');
  const { t } = useTranslation();
  const isMobile = useMobile();
  const projectFormat = useMemo(() => {
    return (
      projectList &&
      projectList.sort(
        (a, b) => (moment(a.date).unix() - moment(b.date).unix()) * (filterCheck === 'Descending' ? 1 : -1),
      )
    );
  }, [projectList, filterCheck]);
  return (
    <div className="flex-between-center tab-project-wrapper">
      <div className="project-left-filter">
        <h4>{t('Sort By')}</h4>
        <Radio.Group onChange={(e) => setFilterCheck(e.target.value)} value={filterCheck}>
          <Space direction={isMobile ? 'horizontal' : 'vertical'} size={isMobile ? 16 : 24}>
            <Radio value={'Descending'}>{t('Earliest submission')}</Radio>
            <Radio value={'Ascending'}>{t('Latest submission')}</Radio>
          </Space>
        </Radio.Group>
      </div>
      <ul className="project-right">
        {projectFormat &&
          projectFormat?.map((project) => (
            <ProjectCard key={project.projectId} project={project} projectVote={projectVote} disabled={disabled} />
          ))}
      </ul>
    </div>
  );
}
