import VideoWrap from 'component/VideoWrap';
import { useMobile } from 'contexts/useStore/hooks';
import { useWindowHeight } from 'hooks/useWindowSize';
import './BannerBg.less';

export default function BannerBg() {
  // const videoRef = useRef(null);
  const winHeight = useWindowHeight();
  const isMobile = useMobile();
  return (
    <div className="banner-bg-wrapper">
      <VideoWrap />
      <div className="bottom-wrapper-mask" style={!isMobile ? { transform: `scaleY(${winHeight / 1200})` } : {}}></div>
    </div>
  );
}
