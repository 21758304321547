import { ChainConstants } from 'constants/ChainConstants';
import { useActiveWeb3React } from 'hooks/web3';
import { useLanguage } from 'i18n';
import { LOCAL_LANGUAGE_LIST } from 'i18n/config';
import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useEffectOnce, useSearchParam } from 'react-use';
import isMobile from 'utils/isMobile';
const body = window.document.getElementsByTagName('body')[0];
const baseTarget = window.document?.getElementById('base-target');
body.className = 'pc-site-content';

const INITIAL_STATE = {};
const StoreContext = createContext<any>(INITIAL_STATE);

declare type StoreState = { mobile?: boolean };
export function useStore(): [StoreState] {
  return useContext(StoreContext);
}

//reducer payload
function reducer(state: any, { type, payload }: any) {
  switch (type) {
    default:
      return Object.assign({}, state, payload);
  }
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { chainId, aelfInstance } = useActiveWeb3React();
  const [mobile, setMobile] = useState<boolean>();
  const { language, changeLanguage } = useLanguage();
  const sLanguage = useSearchParam('language');
  useMemo(() => initialized(chainId, aelfInstance), [chainId, aelfInstance]);

  // className
  useEffect(() => {
    if (!body) return;
    const addClassName = [mobile ? 'mobile-site-content' : 'pc-site-content', `${language}-site-content`];
    body.className = '';
    addClassName.forEach((i) => {
      if (!body.className.includes(i)) body.className = (body.className.trim() + ' ' + i).trim();
    });
  }, [mobile, language]);

  // isMobile
  useEffectOnce(() => {
    if (sLanguage && LOCAL_LANGUAGE_LIST.includes(sLanguage)) changeLanguage(sLanguage);
    const resize = () => {
      const isM = isMobile();
      setMobile(isM.apple.phone || isM.android.phone || isM.apple.tablet || isM.android.tablet);
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  });
  // TODO
  useEffect(() => {
    baseTarget?.setAttribute('target', mobile ? '_self' : '_blank');
  }, [mobile]);

  return (
    <StoreContext.Provider value={useMemo(() => [{ ...state, mobile }, { dispatch }], [state, mobile])}>
      {children}
    </StoreContext.Provider>
  );
}
function initialized(chainId?: string, aelfInstance?: any) {
  if (!chainId) return;
  new ChainConstants(chainId, 'AELF', aelfInstance);
}
