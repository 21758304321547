// import { lazy } from 'react';
import { RoutesProps } from 'types';
import Overview from 'pages/Overview';
import Example from 'pages/Example';
import ActiveInfo from 'pages/ActiveInfo';
// const Overview = lazy(() => import('pages/Overview'));
// const Example = lazy(() => import('pages/Example'));
export const routes: RoutesProps[] = [
  {
    path: '/',
    element: Overview,
  },
  {
    path: '/example',
    element: Example,
  },
  {
    path: '/hackathon/:round',
    element: ActiveInfo,
  },
];
