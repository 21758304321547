import './Header.less';

import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useMobile } from 'contexts/useStore/hooks';
import PcHeader from './PcHeader';
import MHeader from './MHeader';

export default function Header() {
  const [hasBg, setHasBg] = useState<boolean>(false);
  const { pathname } = useLocation();
  const isMobile = useMobile();

  const setHeaderBg = useCallback(() => {
    if (pathname !== '/') return;
    const bonus = document.getElementById('bonus');
    const top = bonus?.getBoundingClientRect().top;
    if (!top || (top && top > 0)) {
      setHasBg(false);
    } else {
      setHasBg(true);
    }
  }, [pathname]);

  useEffect(() => {
    document.addEventListener('scroll', setHeaderBg);
    return () => {
      document.removeEventListener('scroll', setHeaderBg);
    };
  }, [setHeaderBg]);

  return (
    <header className={clsx('app-header flex-between-center', hasBg && 'has-bg', pathname !== '/' && 'no-fixed-top')}>
      {isMobile ? <MHeader /> : <PcHeader />}
    </header>
  );
}
