import { basicActions } from 'contexts/utils';

const ActiveInfoActions = {
  destroy: 'DESTROY',
  setUserProjectList: 'SET_USER_PROJECT_LIST',
};
type UserProject = {
  projectId: string;
  vote: number;
  cost: number;
};
export type ActiveInfoState = {
  userProject: { [x: string]: UserProject } | null;
};

export const basicActiveInfoView = {
  setUserProjectList: {
    type: ActiveInfoActions['setUserProjectList'],
    actions: (userProject: UserProject[]) => basicActions(ActiveInfoActions['setUserProjectList'], { userProject }),
  },
  destroy: {
    type: ActiveInfoActions['destroy'],
    actions: () => basicActions(ActiveInfoActions['destroy']),
  },
};
