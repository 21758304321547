import clsx from 'clsx';
import { cmsUrl } from 'constants/index';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import moment from 'moment';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { PastReviewKeyType, PastReviewType } from 'types';

export default function PastCard({
  item,
  disabledNav,
  style,
  scaleStyle,
  className,
}: {
  item: PastReviewType;
  disabledNav?: boolean;
  style?: CSSProperties;
  scaleStyle?: CSSProperties;
  className?: string;
}) {
  const navigator = useNavigate();
  const { language } = useLanguage();
  const isMobile = useMobile();
  return (
    <div
      key={item.pid}
      className={clsx('past-review-card', className)}
      style={{ ...style, ...scaleStyle }}
      onClick={() => {
        if (disabledNav) return;
        if (item?.[`${language}ExternalLink` as PastReviewKeyType]) {
          window.open(item?.[`${language}ExternalLink` as PastReviewKeyType] as string, isMobile ? '_self' : '_blank');
          return;
        }
        if (!item?.round) return;
        navigator(`/hackathon/${item?.round ?? 1}`);
      }}>
      <div>
        <img src={`${cmsUrl}${item[`${language}Cover` as PastReviewKeyType]}`} />
        <div className="info-wrapper">
          <h4>{item[`${language}Title` as PastReviewKeyType]}</h4>
          <p className="ellipsis3">{item[`${language}Description` as PastReviewKeyType]}</p>
        </div>
        <span>
          {item[`${language}Date` as PastReviewKeyType] &&
            moment(item[`${language}Date` as PastReviewKeyType]).format('LL')}
        </span>
      </div>
    </div>
  );
}
