import { Tabs } from 'antd';
import clsx from 'clsx';
import { cmsUrl } from 'constants/index';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressEn } from 'types';
import { formatVal, unitConverter } from 'utils';
import './ActiveInfo.less';
import Information from './component/Information';
import Material from './component/Material';
import Project from './component/Project';
import Rule from './component/Rule';
import { HackathonKeyInfo, useHackathonInfo, useProjectVote, useUserVote } from './hooks/Updater';
const { TabPane } = Tabs;
// HackathonKeyInfo

export default function ActiveInfo() {
  const [tabKey, setTabKey] = useState<string>('project');
  const isMobile = useMobile();
  const { language } = useLanguage();
  const { t } = useTranslation();
  useUserVote();
  const { projectItem, rulesInfo, materialsInfo, newsList } = useHackathonInfo();
  const projectVote = useProjectVote();
  const bgStyle: any = useMemo(() => {
    const bg = projectItem?.[isMobile ? 'mBanner' : 'pcBanner']?.data?.attributes?.url;
    if (bg) {
      return { backgroundImage: `url(${cmsUrl}${bg})` };
    }
  }, [isMobile, projectItem]);

  const progress = useMemo(() => {
    const { startTime = 0, endTime = 0 } = projectItem ?? {};
    const noTime = moment().unix();
    const end = moment(endTime).unix();
    const start = moment(startTime).unix();
    if (noTime >= end) {
      return ProgressEn.Closed;
    } else if (noTime > start) {
      return ProgressEn.Ongoing;
    } else {
      return ProgressEn.OnLaunched;
    }
  }, [projectItem]);

  return (
    <div className={'active-info-wrapper'}>
      <div className={'active-info-banner'} style={bgStyle ? bgStyle : undefined}>
        <div className="mask"></div>
        <div className="active-info-banner-container">
          <h3>{projectItem?.[`${language}Title` as HackathonKeyInfo] ?? ''}</h3>
          <div className="ellipsis3 active-info-introduction">
            {projectItem?.[`${language}Introduction` as HackathonKeyInfo] ?? ''}
          </div>
          <div className={'active-info-total'}>
            <div className="info">
              <p>{formatVal(projectVote?.totalVotes, 0)}</p>
              <p>{t('Votes received')}</p>
            </div>
            <i />
            <div className="info primary">
              <p>{unitConverter(projectVote?.totalVoteValue, 2)} ELF</p>
              <p>{t('Community contribution')}</p>
            </div>
            <i />
            <div className="info primary">
              <p>{unitConverter(projectVote?.totalSupportValue, 2)} ELF</p>
              <p>{t('Matching pool')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="active-info-content">
        <h2 className="flex-align-center">
          <span>{projectItem?.[`${language}Title` as HackathonKeyInfo] ?? ''}</span>
          <span className={clsx('flex-align-center tab', progress)}>
            <i />
            {t(progress)}
          </span>
        </h2>
        <div className="flex time-organizer">
          <div className="time-range">
            {t('Time')}
            &nbsp;
            {`${moment(projectItem?.startTime).format('LL')}-${moment(projectItem?.endTime).format('LL')}`}
          </div>
          <div className="organizer">
            {t('Host')}
            &nbsp;
            {`${projectItem?.[`${language}Organizer` as HackathonKeyInfo]}`}
          </div>
        </div>
        <Tabs
          defaultActiveKey={tabKey}
          onChange={(key) => {
            setTabKey(key);
          }}>
          <TabPane tab={t('Projects')} key="project">
            <Project
              projectList={projectItem?.projectList}
              projectVote={projectVote?.voteMap}
              disabled={progress !== ProgressEn.Ongoing}
            />
          </TabPane>
          <TabPane tab={t('Rules& Requirements')} key="rule">
            <Rule rulesInfo={rulesInfo?.[language + 'Rule'] ?? ''} />
          </TabPane>
          <TabPane tab={t('Material Required')} key="material">
            <Material materialsInfo={materialsInfo?.[language + 'Material'] ?? ''} />
          </TabPane>
          <TabPane tab={t('News')} key="information">
            <Information newsList={newsList ?? []} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
