import { request } from 'api';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PastReviewType } from 'types';
import './PastReview.less';
import PastReview3DPC from './PastReview3DPC';
import PastReviewMobile from './PastReviewMobile';

export default function PastReview() {
  const [pastList, sePastList] = useState<PastReviewType[]>([]);
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const { language } = useLanguage();
  const isMobile = useMobile();
  const carouselWrapperRef = useRef<any>();

  const getLearningList = useCallback(async () => {
    const res = await request.GET_PAST_REVIEW({
      params: {
        populate: ['pastItem.enCover', 'pastItem.zhCover'],
      },
    });
    if (!res || !res?.data?.[0]?.attributes) return;
    const { pastItem, enTitle, zhTitle } = res?.data?.[0]?.attributes;
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    const list = pastItem.map((item: any) => {
      const { enCover, zhCover } = item;
      const enUrl = enCover?.data?.attributes?.url ?? '';
      const zhUrl = zhCover?.data?.attributes?.url ?? '';
      return {
        ...item,
        enCover: enUrl,
        zhCover: zhUrl,
      };
    });
    console.log(list, 'list===');
    pastItem && sePastList(list);
  }, []);
  useEffect(() => {
    getLearningList();
  }, [getLearningList]);
  // const winHeight = useWindowHeight();
  // const scale = useMemo(() => winHeight / 1200, [winHeight]);
  // const scaleStyle = useMemo(() => ({ transform: `scale(${scale})`, transformOrigin: 'center top' }), [scale]);

  return (
    <div ref={carouselWrapperRef} className="past-review-wrapper">
      <div className={'content'}>
        <h3 className="title-wrapper">
          {/* style={isMobile ? undefined : scaleStyle}> */}
          {title[language]}
        </h3>

        {isMobile ? (
          <PastReviewMobile pastReview={pastList} />
        ) : (
          // <PastReviewPC
          //   carouselWrapperRef={carouselWrapperRef}
          //   pastList={pastList}
          //   scaleStyle={scaleStyle}
          //   scale={scale}
          // />
          <PastReview3DPC pastReview={pastList} />
        )}
      </div>
    </div>
  );
}
