import './Footer.less';
import { Twitter, UTB, Discord, Telegram, Medium } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { useWindowHeight } from 'hooks/useWindowSize';
import isMobile from 'utils/isMobile';
import { useMobile } from 'contexts/useStore/hooks';
const config = [
  {
    title: 'twitter',
    link: 'https://twitter.com/aelfblockchain',
    icon: <Twitter />,
  },
  {
    title: 'YouTube',
    link: 'https://www.youtube.com/c/aelfblockchain',
    icon: <UTB />,
  },
  {
    title: 'Discord',
    link: 'https://discord.com/invite/bgysa9xjvD',
    icon: <Discord />,
  },
  {
    title: 'Telegram',
    link: 'https://t.me/aelfdeveloper',
    icon: <Telegram />,
  },
  {
    title: 'Medium',
    link: 'https://medium.com/aelfblockchain',
    icon: <Medium />,
  },
];

export default function Footer() {
  const { t } = useTranslation();
  const winHeight = useWindowHeight();
  const isMobile = useMobile();
  return (
    <div
      className="flex-between-center footer-wrapper"
      style={!isMobile ? { padding: `${(winHeight / 1200) * 63}px 120px` } : {}}>
      <div style={!isMobile ? { transform: `scale(${winHeight / 1200})`, transformOrigin: 'left center' } : {}}>
        <div className="bottom-logo"></div>
        <p className="copy-right-information">© 2022 aelf Hackathon All rights reserved</p>
      </div>
      <div
        className="contact-us"
        style={!isMobile ? { transform: `scale(${winHeight / 1200})`, transformOrigin: 'right center' } : {}}>
        <div className="flex-between-center">
          <div className="text">{t('Follow Us')}</div>
          <div className="meta-wrapper">
            {config.map((item) => (
              <a key={item.title} href={item.link}>
                {item.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="flex-between-center">
          <div className="text">{t('Contact Us')}</div>
          <a href={`mailto:${'social@aelf.io'}`} className="email-link">
            social@aelf.io
          </a>
        </div>
      </div>
    </div>
  );
}
