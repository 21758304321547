import './QA.less';
import { request } from 'api';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { Close, Open, MOpen, MCloseQA } from 'assets/images';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { isMobile } from 'react-device-detect';
interface QAType {
  id: number;
  enAnswer: string;
  zhAnswer: string;
  enQuestion: string;
  zhQuestion: string;
}
type QAKeyType = keyof QAType;

const { Panel } = Collapse;

export default function QA() {
  const [QAList, setQAList] = useState<QAType[]>([]);
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const { language } = useLanguage();
  const getQAList = useCallback(async () => {
    const res = await request.GET_QA_LIST({
      params: {
        populate: 'QA',
      },
    });
    if (!res || !res?.data?.[0]?.attributes) return;
    const { QA, enTitle, zhTitle } = res?.data?.[0]?.attributes;
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    QA && setQAList(QA);
  }, []);

  useEffect(() => {
    getQAList();
  }, [getQAList]);

  return (
    <div className="qa-wrapper">
      <div className="content">
        <h3 className="title-wrapper">{title[language]}</h3>
        <div className="flex qa-container">
          <Collapse
            accordion
            bordered={false}
            expandIconPosition={'right'}
            expandIcon={(props) => {
              if (props.isActive) {
                return isMobile ? <MOpen /> : <Open />;
              } else {
                return isMobile ? <MCloseQA /> : <Close />;
              }
            }}>
            {QAList.map((item) => (
              <Panel key={item.id} header={item[`${language}Question` as QAKeyType]}>
                {/* <p className="answer">{item[`${language}Answer` as QAKeyType]}</p> */}
                <ReactMarkdown remarkPlugins={[gfm]} className="qa-answer-markdown">
                  {item[`${language}Answer` as QAKeyType] as string}
                </ReactMarkdown>
              </Panel>
            ))}
          </Collapse>
          <div className="qa-logo"></div>
        </div>
      </div>
    </div>
  );
}
