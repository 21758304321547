import { useMobile } from 'contexts/useStore/hooks';
import { useCallback, useEffect } from 'react';
import { useWindowHeight } from './useWindowSize';
let scrollTop = 0;

let timer: any;

const MOBILE_DISTANCE = 20;

export default function useScrollScreen() {
  const winHeight = useWindowHeight();
  const isMobile = useMobile();
  const handleScroll = useCallback(() => {
    // if (isMobile) return;
    const MAIN = window.document.getElementsByClassName('hackathon-layout-content')[0];
    const docScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const dir = docScrollTop - scrollTop;
    scrollTop = docScrollTop;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      const MAIN_CHILDREN = Array.from(MAIN.childNodes);
      MAIN_CHILDREN.forEach((item, index) => {
        if (item.nodeName === 'DIV') {
          const toTop = (item as HTMLElement)?.getBoundingClientRect();
          if (dir >= 0) {
            if (toTop?.top > 0) {
              if (toTop?.top < (isMobile ? winHeight - MOBILE_DISTANCE : (winHeight * 3) / 4)) {
                (item as HTMLElement).scrollIntoView({
                  behavior: 'smooth',
                });
              }
            } else if (toTop?.top < 0) {
              if (index === MAIN_CHILDREN.length - 1) return;
              if (toTop?.top > (isMobile ? -MOBILE_DISTANCE : -winHeight / 4)) {
                (item as HTMLElement).scrollIntoView({
                  behavior: 'smooth',
                });
              }
            }
          } else {
            if (toTop?.top <= 0) {
              if (toTop?.top > (isMobile ? MOBILE_DISTANCE - winHeight : -(winHeight * 3) / 4)) {
                (item as HTMLElement).scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              if (toTop?.top < (isMobile ? MOBILE_DISTANCE : winHeight / 4)) {
                (item as HTMLElement).scrollIntoView({ behavior: 'smooth' });
              }
            }
          }
        }
      });
    }, 300);
  }, [isMobile, winHeight]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
}
