import { request } from 'api';
import Carousel3D from 'component/Carousel3D';
import { useMobile } from 'contexts/useStore/hooks';
import { useLanguage } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import { JudgeInfoType } from 'types';
import './JudgesInfo.less';
import JudgesMobile from './JudgesMobile';

export default function JudgesInfo() {
  const [judgesList, setJudgesList] = useState<JudgeInfoType[]>();
  const [title, setTitle] = useState<{ [x: string]: string }>({});
  const isMobile = useMobile();
  const { language } = useLanguage();
  const getJudgesInfo = useCallback(async () => {
    const res = await request.GET_JUDGES_INFO({
      params: {
        populate: 'judgeItem.cover',
      },
    });
    if (!res || !res?.data?.[0]?.attributes) return;
    const { judgeItem, enTitle, zhTitle } = res?.data?.[0]?.attributes ?? {};
    setTitle({
      en: enTitle ?? '',
      zh: zhTitle ?? '',
    });
    const list = judgeItem?.map((judge: any) => {
      const { cover } = judge;
      const img = cover?.data?.attributes?.url ?? '';
      return {
        ...judge,
        cover: img,
      };
    });
    setJudgesList(list);
  }, []);
  useEffect(() => {
    getJudgesInfo();
  }, [getJudgesInfo]);

  return (
    <div className="judges-info-wrapper">
      <div className="content">
        {judgesList && (
          <>
            <h3>{title[language]}</h3>
            {isMobile ? (
              <JudgesMobile className="judges-mobile-wrapper" judgesList={judgesList} />
            ) : (
              <Carousel3D className={'carouse3d-wrapper'} judgesList={judgesList} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
