import { request } from 'api';
import { basicActiveInfoView } from 'contexts/useActiveInfo/actions';
import { useActiveInfoDispatch } from 'contexts/useActiveInfo/hooks';
import { useActiveWeb3React } from 'hooks/web3';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useEffectOnce } from 'react-use';
import { eventBus } from 'utils';

export const REFRESH_USER_VOTE = 'refresh_user_vote';
interface CMSCompType {
  data?: {
    id: number;
    attributes: {
      url: string;
    };
  };
}

export interface ProjectType {
  projectId: string;
  zhTitle: string;
  enTitle: string;
  zhDescription: string;
  enDescription: string;
  date: string;
  pcCover: CMSCompType;
  mCover: CMSCompType;
  github: string;
  demoLink: string;
  demoVideoLink: string;
}
export type ProjectKeyType = keyof ProjectType;

export interface HackathonInfo {
  id: number;
  zhTitle: string;
  enTitle: string;
  round: string;
  pcBanner: CMSCompType;
  mBanner: CMSCompType;
  startTime: string;
  endTime: string;
  enOrganizer: string;
  zhOrganizer: string;
  enIntroduction: string;
  zhIntroduction: string;
  projectList: ProjectType[];
}

export type HackathonKeyInfo = keyof HackathonInfo;

export interface ProjectItemType {
  projectId: string;
  supportValue: string;
  voteValue: string;
  votes: string;
}

export interface ProjectVoteType {
  totalVotes: string;
  totalVoteValue: string;
  totalSupportValue: string;
  voteMap: { [x: string]: ProjectItemType };
}

export function useProjectVote() {
  const param = useParams();
  const [projectVote, setProjectVote] = useState<ProjectVoteType>();
  const getProjectVote = useCallback(async () => {
    const [res, allVote] = await Promise.all([
      request.GET_PROJECT_VOTE_INFO({
        params: {
          round: param.round,
          page: 0,
          size: 999,
        },
      }),
      request.GET_PROJECT_ALL_VOTE_INFO({
        params: {
          round: param.round,
        },
      }),
    ]);
    let projectVote: any = {};
    if (allVote && !allVote?.error) {
      projectVote = allVote;
    }

    if (!res || res?.error) return;
    let voteMap = {};
    res?.projectList?.forEach((item: any) => {
      voteMap = {
        ...voteMap,
        [item.projectId]: item,
      };
    });
    setProjectVote({
      ...projectVote,
      voteMap,
    });
  }, [param.round]);

  // useEffect(() => {
  //   getProjectVote();
  // }, [getProjectVote]);

  useEffectOnce(() => {
    getProjectVote();
    eventBus.addListener(REFRESH_USER_VOTE, getProjectVote);
    return () => {
      eventBus.removeListener(REFRESH_USER_VOTE, getProjectVote);
    };
  });

  return projectVote;
}

export function useHackathonInfo() {
  const param = useParams();
  const [projectItem, setProjectItem] = useState<HackathonInfo>();
  const [rulesInfo, setRuleInfo] = useState<{ [x: string]: string }>();
  const [materialsInfo, setMaterialsInfo] = useState<{ [x: string]: string }>();
  const [newsList, setNewsList] = useState<{ [x: string]: string }[]>();
  const getInfo = useCallback(async () => {
    const [project, rules, materials, information] = await Promise.all([
      request.GET_PROJECT_INFO({
        params: {
          populate: ['projectList.pcCover', 'projectList.mCover', 'pcBanner', 'mBanner'],
          'filters[round][$eq]': param.round,
        },
      }),
      request.GET_PROJECT_RULE({
        params: {
          'filters[round][$eq]': param.round,
        },
      }),
      request.GET_PROJECT_MATERIALS_INFO({
        params: {
          'filters[round][$eq]': param.round,
        },
      }),
      request.GET_PROJECT_INFORMATION({
        params: {
          populate: 'information',
          'filters[round][$eq]': param.round,
        },
      }),
    ]);
    // if (!project || !project?.data?.[0]?.attributes) return;
    const projectInfo = project?.data?.[0]?.attributes;
    projectInfo && setProjectItem(projectInfo);
    const rulesInfo = rules?.data?.[0]?.attributes;
    rulesInfo && setRuleInfo(rulesInfo);
    const materialsInfo = materials?.data?.[0]?.attributes;
    materialsInfo && setMaterialsInfo(materialsInfo);
    const newsInfo = information?.data?.[0]?.attributes?.information;
    newsInfo && setNewsList(newsInfo);
  }, [param.round]);
  useEffect(() => {
    getInfo();
  }, [getInfo]);
  return useMemo(
    () => ({ projectItem, rulesInfo, materialsInfo, newsList }),
    [projectItem, newsList, rulesInfo, materialsInfo],
  );
}

export function useUserVote() {
  const { account } = useActiveWeb3React();
  const dispatch = useActiveInfoDispatch();
  const param = useParams();
  const getUserVote = useCallback(async () => {
    console.log(account, 'useUserVote===get');

    if (!account) return;
    const res = await request.GET_USER_PROJECT_VOTE_INFO({
      params: {
        user: account,
        round: param.round,
      },
    });
    if (!res || res?.error) return;
    let map: any = {};
    res?.userProjectList.map((project: any) => {
      map = {
        ...map,
        [project?.projectId]: project,
      };
    });

    dispatch(basicActiveInfoView.setUserProjectList.actions(map));
  }, [account, dispatch, param.round]);
  // useEffect(() => {
  //   getUserVote();
  // }, [getUserVote]);
  useEffect(() => {
    getUserVote();
    eventBus.addListener(REFRESH_USER_VOTE, getUserVote);
    return () => {
      eventBus.removeListener(REFRESH_USER_VOTE, getUserVote);
    };
  }, [getUserVote]);
}
