import { useState, useMemo, useCallback } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { config } from 'react-spring';
import { useLanguage } from 'i18n';
import { JudgeInfoType } from 'types';
import JudgeCard from 'component/JudgeCard';
import { useMobile } from 'contexts/useStore/hooks';
import clsx from 'clsx';
import BaseCarousel3D from 'components/BaseCarousel3D';
let startX: any;
let endX: any;
const MIN_TOUCH_DISTANCE = 50;

export default function Carousel3D({ judgesList, className }: { judgesList: JudgeInfoType[]; className?: string }) {
  const [goToSlide, setGoToSlide] = useState<number>(0);
  const { language } = useLanguage();
  const isMobile = useMobile();
  const slides = useMemo(() => {
    return judgesList?.map((judge, index) => {
      return {
        key: judge.id,
        onClick: () => {
          setGoToSlide(index);
        },
        content: (
          <JudgeCard judge={judge} language={language} index={index} current={goToSlide} judgeLen={judgesList.length} />
        ),
      };
    });
  }, [goToSlide, judgesList, language]);

  const handleTouchStart = useCallback((e) => {
    startX = e.touches[0].clientX;
  }, []);
  const handleTouchMove = useCallback((e) => {
    endX = e.touches[0].clientX;
  }, []);
  const handleTouchEnd = useCallback(() => {
    const distance = Math.abs(startX - endX);
    if (distance > MIN_TOUCH_DISTANCE) {
      if (startX > endX) {
        // carousel.next();
        setGoToSlide((v) => {
          const next = v + 1;
          if (next >= judgesList.length) return 0;
          return next;
        });
      } else {
        // carousel.prev();
        setGoToSlide((v) => {
          const prev = v - 1;
          if (prev < 0) return judgesList.length - 1;
          return prev;
        });
      }
    }
  }, [judgesList.length]);

  return (
    <div
      className={className}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <BaseCarousel3D
        showNavigation={false}
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={2}
        animationConfig={config.gentle}
        baseScale={2}
        baseTranslate={45}
      />
      {isMobile && (
        <ul className="carouse3d-dot-wrapper">
          {judgesList.map((_, index) => (
            <li
              className={clsx(goToSlide === index && 'current-dot')}
              key={index}
              onClick={() => setGoToSlide(index)}></li>
          ))}
        </ul>
      )}
    </div>
  );
}
