import clsx from 'clsx';
import { cmsUrl } from 'constants/index';
import { JudgeInfoKey, JudgeInfoType } from 'types';
import './JudgeCard.less';

interface JudgeCardProps {
  judge: JudgeInfoType;
  current: number;
  index: number;
  language: string;
  judgeLen: number;
}
export default function JudgeCard({ judge, current, index, language, judgeLen }: JudgeCardProps) {
  const isCurrent = current === index;
  return (
    <div key={judge.id} className={clsx('judge-item-wrapper')}>
      <div className={clsx('judge-cover', isCurrent && 'current-judge-cover')}>
        {isCurrent && <div className="border-top"></div>}
        <img src={`${cmsUrl}${judge.cover}`} />
        {isCurrent && <div className="border-bottom"></div>}
      </div>
      {
        // ((index + 1) % judgeLen === current ||
        //   (index - 1) % judgeLen === current ||
        //   (current === judgeLen - 1 && index === 0) ||
        isCurrent && (
          <h2 className={clsx('judge-name', isCurrent && 'current-judge-name')}>
            {judge[`${language}JudgeName` as JudgeInfoKey]}
          </h2>
        )
      }
      {<div className="judge-introduction">{isCurrent ? judge[`${language}Introduction` as JudgeInfoKey] : ''}</div>}
    </div>
  );
}
