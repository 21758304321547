export interface RoutesProps {
  path: string;
  exact?: boolean;
  strict?: boolean;
  element: React.ComponentType<any>;
  authComp?: React.ComponentType<any>;
}
export type ChainType = 'ERC' | 'AELF';

export interface JudgeInfoType {
  cover: string;
  enJudgeName: string;
  zhJudgeName: string;
  enIntroduction: string;
  zhIntroduction: string;
  id: number;
}
export type JudgeInfoKey = keyof JudgeInfoType;

export enum ProgressEn {
  Closed = 'Closed',
  OnLaunched = 'OnLaunched',
  Ongoing = 'Ongoing',
}

export interface PastReviewType {
  id: number;
  pid: string;
  enTitle: string;
  zhTitle: string;
  zhDate: string;
  zhCover: string;
  enDate: string;
  enCover: string;
  enDescription: string;
  zhDescription: string;
  order: number;
  round: string;
  enExternalLink: string | null;
  zhExternalLink: string | null;
}

export type PastReviewKeyType = keyof PastReviewType;
