import BigNumber from 'bignumber.js';
import { ChainConstants } from 'constants/ChainConstants';
import EventEmitter from 'events';

export const eventBus = new EventEmitter();

// returns the checksummed address if the address is valid, otherwise returns false

export function getEtherscanLink(data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  const prefix = ChainConstants.constants.CHAIN_INFO.exploreUrl;
  switch (type) {
    case 'transaction': {
      return `${prefix}tx/${data}`;
    }
    case 'token': {
      return `${prefix}token/${data}`;
    }
    case 'block': {
      return `${prefix}block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}address/${data}`;
    }
  }
}

export function shortenAddress(address: string | null, chars = 4): string {
  const parsed = address;
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export const sleep = (time: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export function shortenString(address: string | null, chars = 10): string {
  const parsed = address;
  if (!parsed) {
    return '';
  }
  return `${parsed.substring(0, chars)}...${parsed.substring(parsed.length - chars)}`;
}
export function getExploreLink(data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  const prefix = ChainConstants.constants.CHAIN_INFO.exploreUrl;
  switch (type) {
    case 'transaction': {
      return `${prefix}tx/${data}`;
    }
    case 'token': {
      return `${prefix}token/${data}`;
    }
    case 'block': {
      return `${prefix}block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}address/${data}`;
    }
  }
}

export function formatVal(number?: number | string, decimals = 2) {
  const n = new BigNumber(number ?? 0);
  if (n.isNaN() || n.isZero()) return '--';
  return n.toFormat(decimals);
}

export * from './converter';
